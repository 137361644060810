import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FieldType } from '@shared/Types/FieldType';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import {
  HCP_DB_MAPPINGS,
  TTuple,
  influenceOptions,
} from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { THCPProjectsData } from '@shared/interfaces/THCP';
import {
  FilterOperator,
  TProject,
  TTagItem,
  TTierFieldItem,
} from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { TReportDataFilter } from '../reports-review/report-filters/report-filters.component';
import { CategoriesService } from '../services/CategoriesService';

@Component({
  selector: 'app-value-editor',
  templateUrl: './value-editor.component.html',
  styleUrls: ['./value-editor.component.scss'],
})
export class ValueEditorComponent implements OnInit, OnDestroy {
  @Input()
  public fieldType: FieldType = `hcp`;
  @Input()
  public filterItem: TReportDataFilter = {
    id: ``,
    operator: FilterOperator.Equal,
    property: ``,
    type: `regular`,
    value: 0,
  };
  @Input()
  public projectId: string = ``;
  @Input()
  public onInputChange: (value: number | string | boolean) => void = (
    value
  ) => {
    //
  };

  public availableTags: TTagItem[] = [];
  public selectedTags: string[] = [];

  private _subs: Subscription[] = [];
  private _tagFieldId: keyof THCPProjectsData = `memberships_committees`;

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    this._subs.push(
      this._categoriesService.getCategoriesObservable().subscribe(() => {
        const project: TProject | undefined =
          this._categoriesService.getProject(this.projectId);
        if (!isNullOrUndefined(project)) {
          this.availableTags =
            project.hcpTagsDefinitions.find(
              (def) => def.connEctedFieldId === this._tagFieldId
            )?.tags ?? [];
        }
      })
    );
    this.selectedTags = (this.filterItem.tags ?? []).map((it) => it.tag);
  }

  public ngOnDestroy(): void {}

  public getTiers(fieldId: string): TTierFieldItem[] {
    const data = this._categoriesService.getTiersForField(
      this.projectId,
      fieldId,
      this.fieldType
    );
    return data;
  }

  public getAvailableRegions1(): string[] {
    const project: TProject | undefined = this._categoriesService.getProject(
      this.projectId
    );
    if (project !== undefined) {
      return project.regions1.map((r) => r.name);
    }
    return [];
  }

  public getAvailableRegions2(): string[] {
    const project: TProject | undefined = this._categoriesService.getProject(
      this.projectId
    );
    if (project !== undefined) {
      return project.regions2.map((r) => r.name);
    }
    return [];
  }

  public getTupleList(): TTuple[] {
    if (this.filterItem.property === `kols_status`) {
      return influenceOptions;
    }
    return [];
  }

  public getMappings(): Map<string, TDBFieldMappingItem> {
    switch (this.fieldType) {
      case 'center':
        return CENTER_DB_MAPPINGS;
      default:
        return HCP_DB_MAPPINGS;
    }
  }

  public hasType(): boolean {
    return !isNullOrUndefined(
      this.getMappings().get(this.filterItem.property)?.type
    );
  }

  public isRegionField(): boolean {
    return (
      this.getMappings().get(this.filterItem.property)?.isRegionField === true
    );
  }

  public isTierField(): boolean {
    return this.getMappings().get(this.filterItem.property)?.tierField === true;
  }

  public isNumberType(): boolean {
    return this.getMappings().get(this.filterItem.property)?.type === 'number';
  }

  public isBooleanType(): boolean {
    return this.getMappings().get(this.filterItem.property)?.type === 'boolean';
  }

  public isStringType(): boolean {
    return this.getMappings().get(this.filterItem.property)?.type === 'string';
  }

  public isTupleField(): boolean {
    return this.getMappings().get(this.filterItem.property)?.tupleList === true;
  }

  public isTagField(): boolean {
    return (
      this.getMappings().get(this.filterItem.property)?.isTagField === true
    );
  }

  public handleTagsChangeChange(event: MatSelectChange): void {
    this.filterItem.tags = this.availableTags.filter((t) =>
      (<string[]>event.value).includes(t.tag)
    );
  }
}
