<div *ngIf="params.dataLoaded" [ngClass]="getWrapperCSS()">
  <div
    *ngIf="params.actionsAvailable === true"
    class="inline-flex items-center columns-2 justify-between flex-row-reverse mb-2"
  >
    <div class="flex items-center">
      <button
        *ngIf="risingStarButtonAvailable()"
        mat-icon-button
        aria-label="Save rising star"
        matTooltip="Save rising star"
        (click)="saveRisingStar()"
      >
        <mat-icon>stars</mat-icon>
      </button>
      <button
        *ngIf="mergeRowsAvailable()"
        mat-icon-button
        aria-label="Merge selected rows"
        matTooltip="Merge selected rows"
        (click)="mergeRows()"
      >
        <mat-icon>merge_type</mat-icon>
      </button>
      <button
        *ngIf="optInOutAvailable()"
        mat-icon-button
        aria-label="Opt in/out"
        matTooltip="Opt in out selected HCPs"
        (click)="optInOut()"
      >
        <mat-icon> contact_mail</mat-icon>
      </button>
      <button
        *ngIf="prioritySettingsAvailable()"
        mat-icon-button
        aria-label="Priority settings"
        matTooltip="Set priority of selected rows"
        (click)="openPrioritySettings()"
      >
        <mat-icon>star</mat-icon>
      </button>
      <button
        *ngIf="selectionToggleAvailable()"
        mat-icon-button
        aria-label="Toggle selection mode"
        matTooltip="Toggle selection mode"
        (click)="toggleSelectionMode()"
      >
        <mat-icon>select_all</mat-icon>
      </button>
      <button
        *ngIf="addNewAvailable()"
        mat-icon-button
        aria-label="Add new"
        matTooltip="{{ params.addNewParams?.tooltip }}"
        (click)="params.addNewParams?.addNewCallback()"
      >
        <mat-icon>add_box</mat-icon>
      </button>
      <input
        hidden
        (change)="importEngagement($event)"
        #fileInput
        type="file"
        id="file"
      />
      <button
        *ngIf="importEngagementAvailable()"
        mat-icon-button
        aria-label="Import engagement"
        matTooltip="Import engagement"
        (click)="fileInput.click()"
      >
        <mat-icon> import_contacts</mat-icon>
      </button>
      <app-import-export-panel
        [params]="importExportParams"
      ></app-import-export-panel>
      <app-pagination
        *ngIf="topPaginationVisible()"
        [paginationParams]="paginationParams"
      ></app-pagination>
    </div>
    <mat-form-field class="no-margin" *ngIf="isFilterVisible()">
      <mat-label>Search (type at least 3 signs)</mat-label>
      <!-- <input matInput (input)="applyFilter($event)" placeholder="Ex. ium" /> -->
      <input
        matInput
        [ngModel]="searchModel"
        (ngModelChange)="changed($event)"
        placeholder="Ex. ium"
      />
    </mat-form-field>
  </div>
  <div
    *ngIf="!params.actionsAvailable"
    class="inline-flex columns-2 items-center justify-between flex-row-reverse"
  >
    <app-import-export-panel
      [params]="importExportParams"
    ></app-import-export-panel>
    <app-pagination
      *ngIf="topPaginationVisible()"
      [paginationParams]="paginationParams"
    ></app-pagination>
  </div>
  <div *ngIf="selectedSortsVisible()" class="flex px-4 items-center mb-2">
    <mat-label class="mr-2">Selected sorts</mat-label>
    <mat-chip-listbox aria-label="Sorts">
      <mat-chip-option *ngFor="let sortItem of selectedSorts">{{
        sortItem
      }}</mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div
    class="overflow-auto"
    [ngClass]="simplePaginationVisible() ? '' : 'inline-flex'"
  >
    <div *ngIf="simplePaginationVisible()" class="mb-4 flex justify-between">
      <h3 *ngIf="title" class="mat-h3">{{ title }}</h3>
      <mat-paginator
        [pageSizeOptions]="params.pageSizeOptions"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
    <table
      mat-table
      [dataSource]="params.dataSource"
      [ngClass]="getTableCSS()"
      class="table-fixed"
      matSort
    >
      <ng-container
        *ngFor="let column of params.columns"
        [matColumnDef]="column.columnDef"
      >
        <th
          class="with-resize"
          mat-header-cell
          *matHeaderCellDef
          [ngStyle]="getStyles(column)"
          mat-sort-header
          [disabled]="!isSimpleSortHeader(column.header)"
        >
          <div
            class="resize-wrapper"
            [ngClass]="getHeaderCSS(column)"
            mwlResizable
            [enableGhostResize]="true"
            (resizeEnd)="onResizeEnd($event, column)"
          >
            <ng-container *ngIf="column.sortable">
              <span class="flex" *ngIf="extendedSortAvailable()">
                <span class="cursor-pointer flex w-full justify-between mr-1">
                  <span
                    (click)="headerClick(column)"
                    class="header-name inline-block text-ellipsis overflow-hidden whitespace-nowrap"
                    >{{ column.header }}</span
                  >

                  <mat-icon
                    class="sort-icon sort-icon--up"
                    [ngClass]="getUpSortArrowCSS(column)"
                    (click)="headerClick(column)"
                    >arrow_upward</mat-icon
                  >

                  <mat-icon
                    class="sort-icon"
                    [ngClass]="getDownSortArrowCSS(column)"
                    (click)="headerClick(column)"
                    >arrow_downward</mat-icon
                  >
                </span>
              </span>
              <span
                class="block whitespace-nowrap overflow-hidden text-ellipsis"
                *ngIf="noneSortAvailable()"
              >
                {{ column.header }}
              </span>
              <span
                class="block whitespace-nowrap overflow-hidden text-ellipsis"
                *ngIf="simpleSortAvailable()"
              >
                {{ column.header }}
              </span>
            </ng-container>
            <ng-container *ngIf="!column.sortable">
              <span class="flex gap-2 items-center">
                <span *ngIf="!isDataColumn(column)" class="flex ml-2">
                  <mat-checkbox
                    color="primary"
                    *ngIf="selectionAvailable()"
                    (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                  >
                  </mat-checkbox>
                </span>
                <span
                  class="header-name block text-ellipsis overflow-hidden whitespace-nowrap"
                >
                  {{ column.header }}</span
                >
              </span>
            </ng-container>
            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }"
            ></div>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [ngStyle]="getStyles(column)">
          <div>
            <ng-container *ngIf="isDataColumn(column)">
              <ng-container *ngIf="column.linkContent">
                <div
                  *ngIf="navigateToButtonAvailable()"
                  (click)="navigateTo(row)"
                  class="text-ellipsis overflow-hidden p-2 link"
                  [innerHTML]="column.cell(row)"
                ></div>
              </ng-container>
              <ng-container *ngIf="!column.linkContent">
                <div
                  class="text-ellipsis overflow-hidden p-2"
                  [innerHTML]="column.cell(row)"
                ></div>
              </ng-container>
            </ng-container>
            <div
              *ngIf="!isDataColumn(column)"
              class="flex gap-2 overflow-hidden ml-3 justify-center"
            >
              <mat-checkbox
                color="primary"
                *ngIf="selectionAvailable()"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
              <button
                *ngIf="editButtonAvailable()"
                mat-icon-button
                aria-label="Edit"
                (click)="editRow(row)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="params.displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: params.displayedColumns"
        (click)="toggleRow(row)"
      ></tr>
    </table>
  </div>
  <div
    *ngIf="params.actionsAvailable === true"
    class="inline-flex columns-2 justify-between my-2"
  >
    <div></div>
    <app-pagination
      *ngIf="bottomPaginationVisible()"
      [paginationParams]="paginationParams"
    ></app-pagination>
  </div>
</div>
<div *ngIf="!params.dataLoaded">
  <mat-spinner></mat-spinner>
</div>
