export interface TProductUse {
  product: ProductName;
  sales: TProductSales[];
}

export interface TProductSales {
  time_period: string;
  sales_count: number;
}

export interface TProductSalesTableData {
  product: ProductName;
  time_period: string;
  sales_count: number;
}

export type ProductName = string;
export type FormularyStatus = 1 | 2 | 3;

// TODO: move to utils, test it
export function validateTimePeriodImportFormat(timePeriod: string): boolean {
  const pattern1 = /^M(0[1-9]|1[0-2])\d{4}$/;
  return pattern1.test(timePeriod);
}

export function validateTimePeriodSaveFormat(timePeriod: string): boolean {
  const pattern = /^M(0[1-9]|1[0-2])\d{4}$/;
  return pattern.test(timePeriod);
}

export function convertToTableData(productUses: TProductUse[]): TProductSalesTableData[] {
  return productUses.flatMap((productUse) => {
    return productUse.sales.map((sale) => ({
      product: productUse.product,
      time_period: sale.time_period,
      sales_count: sale.sales_count
    }));
  });
}
