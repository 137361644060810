import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { TCategory } from '@shared/interfaces/TCategory';
import { TUser, UserLevel, UserStatus } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../services/CategoriesService';

export class TreeItemNode {
  children?: TreeItemNode[];
  name: string;
  selected: boolean;
  id: string;
}
@Component({
  selector: 'app-projects-selector',
  templateUrl: './projects-selector.component.html',
  styleUrls: ['./projects-selector.component.scss'],
})
export class ProjectsSelectorComponent implements OnDestroy, OnInit {
  @Input()
  public newUserData: Partial<TUser> = {
    categories: [],
    countries: [],
    email: ``,
    firstName: ``,
    lastName: ``,
    level: UserLevel.User,
    password: ``,
    projects: [],
    projectsRegions: [],
    status: UserStatus.Active,
  };
  @Input() public visibleForAdminOnly: boolean = false;
  public treeControl = new NestedTreeControl<TreeItemNode>(
    (node) => node.children
  );
  public dataSource = new MatTreeNestedDataSource<TreeItemNode>();
  @ViewChild('tree')
  public tree: MatTree<TreeItemNode>;
  private subs: Subscription[] = [];
  public projectsRegions: Record<
    string,
    { name: string; selected: boolean }[]
  > = {};

  constructor(private _categoriesService: CategoriesService) {}

  public ngOnInit(): void {
    this.subs.push(
      this._categoriesService
        .getCategoriesObservable()
        .subscribe((categories) => {
          this.buildCategoriesTree(categories);
        })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public hasChild = (_: number, node: TreeItemNode) =>
    !!node.children && node.children.length > 0;

  public buildCategoriesTree(categories: TCategory[]): void {
    const pRegions: Record<string, { name: string; selected: boolean }[]> = {};
    const treeData: TreeItemNode[] = categories.map((c) => {
      const leaf: TreeItemNode = {
        id: c.categoryId,
        name: c.name,
        selected: (this.newUserData?.categories ?? []).includes(c.categoryId),
      };
      if (c.projects.length > 0) {
        leaf.children = c.projects.map((p) => {
          const reg1 = p.regions1 ?? [];
          const reg2 = p.regions2 ?? [];
          pRegions[p.id] = [...reg1, ...reg2].map((r) => {
            return {
              name: r.name,
              selected: this.isRegionSelected(r.name, p.id),
            };
          });
          return {
            id: p.id,
            name: p.name,
            selected: this.isProjectSelected(p.id),
          };
        });
      }
      return leaf;
    });
    this.projectsRegions = pRegions;
    this.dataSource.data = treeData;
    this.treeControl.dataNodes = treeData;
  }

  public isProjectSelected(projectId: string): boolean {
    return (this.newUserData?.projects ?? []).includes(projectId);
  }

  private isRegionSelected(regionName: string, projectId: string): boolean {
    if (isNullOrUndefined(this.newUserData?.projectsRegions)) {
      return false;
    }
    const userPrRegions = this.newUserData.projectsRegions || [];
    if (userPrRegions.length === 0) {
      return false;
    }
    const project = userPrRegions.find((r) => r.projectId === projectId);
    if (isNullOrUndefined(project)) {
      return false;
    }
    return project.regions.includes(regionName);
  }

  public onCheckboxChange(node: TreeItemNode): void {
    if (this.hasChild(NaN, node)) {
      node.children?.forEach((child) => {
        child.selected = node.selected;
      });
    } else {
      const parent: TreeItemNode | undefined = this.dataSource.data.find((it) =>
        (it.children ?? []).map((it) => it.id).includes(node.id)
      );
      if (!isNullOrUndefined(parent)) {
        if (node.selected) {
          // select category if project is selected
          parent.selected = true;
        } else {
          // unselect category if no projects are selected
          const childrenWithoutCurrent = (parent.children ?? []).filter(
            (it) => it.id !== node.id
          );
          if (
            !node.selected &&
            childrenWithoutCurrent.every((it) => !it.selected)
          ) {
            parent.selected = false;
          }

          // unselect all regions if project is unselected
          this.projectsRegions[node.id].forEach((r) => (r.selected = false));
        }
      }
    }

    const categories: string[] = [];
    const projects: string[] = [];
    this.dataSource.data.forEach((c) => {
      if (c.selected) {
        categories.push(c.id);
      }
      (c.children ?? []).forEach((p) => {
        if (p.selected) {
          projects.push(p.id);
        }
      });
    });
    this.newUserData.categories = categories;
    this.newUserData.projects = projects;
    const projectsRegionsArray: { projectId: string; regions: string[] }[] = [];
    Object.keys(this.projectsRegions).forEach((key) => {
      projectsRegionsArray.push({
        projectId: key,
        regions: this.projectsRegions[key]
          .filter((r) => r.selected)
          .map((r) => r.name),
      });
    });
    this.newUserData.projectsRegions = projectsRegionsArray;
  }

  public onRegionCheckboxChange(
    regionNames: string[],
    projectId: string
  ): void {
    // Get existing regions array or default to an empty one
    const regions = this.newUserData.projectsRegions ?? [];

    // Find or create the ProjectRegions entry for this project
    let projectRegion = regions.find((pr) => pr.projectId === projectId);
    if (!projectRegion) {
      projectRegion = { projectId, regions: [] };
      regions.push(projectRegion);
    } else {
      projectRegion.regions = regionNames;
    }

    // Update the userData
    this.newUserData.projectsRegions = regions;
  }
}
