import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TTreatment } from '@shared/interfaces/TProject';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-treatment-uses',
  templateUrl: './treatment-uses.component.html',
  styleUrls: ['./treatment-uses.component.scss'],
})
export class TreatmentUsesComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TreatmentUsesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
  }
  public params: TBaseSettingsTabParams;

  public newTreatmentUseData: TTreatment = {
    description: ``,
    name: ``,
  };

  public getTreatmentUses(): TTreatment[] {
    return this.params.selectedProject?.treatment_uses ?? [];
  }

  public add() {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.treatment_uses === undefined
    ) {
      this.params.selectedProject.treatment_uses = [];
      this.params.selectedProject?.treatment_uses.push(
        structuredClone(this.newTreatmentUseData)
      );
    } else {
      this.params.selectedProject?.treatment_uses.push(
        structuredClone(this.newTreatmentUseData)
      );
    }
  }

  private _isNameDuplicated(
    treatment_uses: TTreatment[] | undefined,
    name: string
  ): boolean {
    return (treatment_uses ?? []).map((r) => r.name).includes(name);
  }

  public addAvailable(): boolean {
    const formIsValid =
      this.newTreatmentUseData.name !== `` &&
      this.newTreatmentUseData.description !== ``;
    const belowLimit = this.params.selectedProject?.treatment_uses
      ? this.params.selectedProject?.treatment_uses.length < 9
      : true;
    return (
      formIsValid &&
      !this._isNameDuplicated(
        this.params.selectedProject?.treatment_uses,
        this.newTreatmentUseData.name
      ) &&
      belowLimit
    );
  }

  public remove(region: TTreatment): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions1 !== undefined
    ) {
      const idx: number = this.params.selectedProject.treatment_uses.findIndex(
        (r) => r.name === region.name
      );
      this.params.selectedProject.treatment_uses.splice(idx, 1);
    }
  }

  ngOnInit(): void {}
}
