import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TCenter,
  TCenterProjectsData,
  buildCenterMock,
  buildCenterProjectDataMock,
} from '@shared/interfaces/TCenter';
import { THCP } from '@shared/interfaces/THCP';
import { TProductSales } from '@shared/interfaces/TProductUse';
import { TProject, TTierFieldItem } from '@shared/interfaces/TProject';
import { DEFAULT_APP_SETTINGS, TSettings } from '@shared/interfaces/TSettings';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import { AutocompleteWrapperComponent } from 'src/app/autocomplete-wrapper/autocomplete-wrapper.component';
import {
  TCenterParams,
  TComponentType,
} from 'src/app/interfaces/TComponentParams';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { CentersService } from 'src/app/services/CentersService';
import { RegionsService } from 'src/app/services/RegionsService';
import { SettingsService } from 'src/app/services/SettingsService';
import { NEW_CENTER_ID } from '../center.component';
import { sortedSales } from './../../../utils/sales';

@Component({
  selector: 'app-center-profile',
  templateUrl: './center-profile.component.html',
  styleUrls: ['./center-profile.component.scss'],
})
export class CenterProfileComponent {
  @Input() public centerParams: TCenterParams = {
    __type: TComponentType.center,
    categoryId: ``,
    centerId: ``,
    projectId: ``,
  };
  @Input() public centerData: TCenter = {
    ...buildCenterMock({}),
  };

  @Output() customMetricsFormValidityChange = new EventEmitter<boolean>();
  @Output() customMetricsFormValueChange = new EventEmitter<any>();

  @ViewChild(AutocompleteWrapperComponent)
  private countryAutocomplete: AutocompleteWrapperComponent;

  public centerScore = 0;
  public centerPotentialScore = 0;
  public centerUtilScore = 0;
  public ctScore = 0;
  public usageOptions: string[] = [`High`, `Average`, `Low`, `None`];
  public articlesCount: number = 0;
  public hcps: THCP[] = [];
  public hcpsCount: number = 0;
  public existingCenterSearch: string = ``;
  public centerControl: FormControl<TCenter | null>;
  public availableRegions1: string[] = [];
  public availableRegions2: string[] = [];
  public availableTreatmentGoals: string[] = [];
  public availableTreatmentOptions: string[] = [];
  public availableTreatmentUses: string[] = [];
  public filteredOptions: Observable<TCenter[]>;
  public isLoading: boolean = false;
  public latestSale: TProductSales | undefined;

  public appSettings: TSettings = structuredClone(DEFAULT_APP_SETTINGS);
  public countries: string[] = [];
  public selectedCountryObservable: Observable<string> = new Observable();
  public project: TProject | undefined;
  public centerNews = '';

  private _subs: Subscription[] = [];
  private isNewFromOtherProject: boolean = false;

  constructor(
    public deleteConfirmationDialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _centersService: CentersService,
    private _regionsService: RegionsService,
    private _categoriesService: CategoriesService,
    private _settingsService: SettingsService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((settings) => {
        this.appSettings = settings;
      })
    );
    this._subs.push(
      this._regionsService
        .getCountriesObservable()
        .subscribe(
          (regions) => (this.countries = regions.map((c) => c.viewValue))
        )
    );
  }

  ngOnChanges() {
    if (this.isCenterSelected()) {
      this.countryAutocomplete?.setValue(this.centerData.c_country);
      const project: TProject | undefined = this._categoriesService.getProject(
        this.centerParams.projectId
      );
      // if (project !== undefined) {
      //   this._setSalesData(project);
      // }
      const projectData: TCenterProjectsData =
        this._centersService.getProjectData(
          this.centerData,
          this.centerParams.projectId
        );
      this.centerScore = projectData.valueScore;
      this.centerPotentialScore = isNaN(projectData.potentialScore)
        ? 0
        : projectData.potentialScore;
      this.centerUtilScore = isNaN(projectData.utilScore)
        ? 0
        : projectData.utilScore;

      this.ctScore = isNaN(projectData.clinicalTrialsScore)
        ? 0
        : projectData.clinicalTrialsScore;

      if (this.getProjectData().treatment_goals_enabled === undefined) {
        this.getProjectData().treatment_goals_enabled = [];
      }

      if (this.getProjectData().treatment_options_validated === undefined) {
        this.getProjectData().treatment_options_validated = [];
      }

      if (this.getProjectData().treatment_options_counts === undefined) {
        this.getProjectData().treatment_options_counts = [];
      }

      if (this.getProjectData().treatment_uses_enabled === undefined) {
        this.getProjectData().treatment_uses_enabled = [];
      }

      const sales = sortedSales(
        this.getProjectData().products_use &&
          this.getProjectData().products_use.length > 0
          ? this.getProjectData().products_use[0].sales
          : []
      );
      this.latestSale = sales[sales.length - 1];

      this._subs.push(
        this._categoriesService
          .getCategoriesObservable()
          .subscribe((categories) => {
            const project: TProject | undefined = categories
              .find((c) => c.categoryId === this.centerParams.categoryId)
              ?.projects.find((p) => p.id === this.centerParams.projectId);
            if (project !== undefined) {
              this.project = project;
              this.availableRegions1 = project.regions1.map((r) => r.name);
              this.availableRegions2 = project.regions2.map((r) => r.name);
              this.availableTreatmentGoals = project.treatment_goals.map(
                (r) => r.name
              );
              this.availableTreatmentOptions = project.treatment_options.map(
                (r) => r.name
              );
              this.availableTreatmentUses = project.treatment_uses.map(
                (r) => r.name
              );
              // this._setSalesData(project);
            }
          })
      );
      this._setCenterOptions();
    }
  }

  public getProjectData(): TCenterProjectsData {
    const projectData: TCenterProjectsData =
      this._centersService.getProjectData(
        this.centerData,
        this.centerParams.projectId
      );
    return projectData;
  }

  public getFieldDescription(field: string): string {
    return this._categoriesService.getFieldDescription(
      field,
      this.centerParams.projectId
    );
  }

  public getTiers(fieldId: string): TTierFieldItem[] {
    const data = this._categoriesService.getTiersForField(
      this.centerParams.projectId,
      fieldId,
      `center`
    );
    return data;
  }

  public isNew(): boolean {
    return (
      this.centerParams.centerId === NEW_CENTER_ID || this.isNewFromOtherProject
    );
  }

  public formattedSale = (): string => {
    if (this.latestSale) {
      // convert M012024 to 01/2024
      const month = this.latestSale.time_period.substring(1, 3);
      const year = this.latestSale.time_period.substring(3);
      return `${month}/${year}`;
    }
    return '';
  };

  public fillCenter(center: TCenter): void {
    const clonedCenter: TCenter = structuredClone(center);
    if (clonedCenter.projects.includes(this.centerParams.projectId)) {
      const centerParams: TCenterParams = {
        centerId: center.c_id,
        categoryId: this.centerParams.categoryId ?? ``,
        projectId: this.centerParams.projectId ?? ``,
        __type: TComponentType.center,
      };
      this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
        relativeTo: this._route,
      });
    } else {
      clonedCenter.projects.push(this.centerParams.projectId);
      const newProjectsData: TCenterProjectsData = buildCenterProjectDataMock({
        projectId: this.centerParams.projectId,
      });
      clonedCenter.projectsData.push(newProjectsData);
      this.onCenterSelectCallback(clonedCenter, false);
    }
  }

  private onCenterSelectCallback(center: TCenter, reset: boolean): void {
    if (reset) {
      this.centerData = center;
      this.centerParams = {
        ...this.centerParams,
        centerId: NEW_CENTER_ID,
      };
    } else {
      this.centerData = center;
      const clonedParams = structuredClone(this.centerParams);
      clonedParams.centerId = center.c_id;
      this.centerParams = clonedParams;
      this.isNewFromOtherProject = true;
    }
  }

  public onSelectionChange(newValue: string) {
    this.centerData.c_country = newValue;
  }

  public OnChangeTreatmentGoal(newValue: boolean, i: number) {
    this.getProjectData().treatment_goals_enabled[i] = newValue;
  }

  onTherapyChange(value: number, index: number) {
    // get sum of number is array
    // make a copy to be sure nothing is changed
    const copy = [...this.getProjectData().treatment_options_counts];
    copy[index] = value;
    const sum = copy.reduce((a, b) => a + b, 0);
    this.getProjectData().treatment_option_total_count = sum;
  }

  // ugly, but it works
  // onTherapy1Change(value: number) {
  //   this.getProjectData().center_therapy_total_count =
  //     value +
  //     (this.getProjectData().center_therapy2_count || 0) +
  //     (this.getProjectData().center_therapy3_count || 0) +
  //     (this.getProjectData().center_therapy4_count || 0) +
  //     (this.getProjectData().center_therapy5_count || 0) +
  //     (this.getProjectData().center_therapy6_count || 0) +
  //     (this.getProjectData().center_therapy_unknown_count || 0);
  // }

  public displayFn(center: TCenter): string {
    return center && center.c_name ? `${center.c_name}` : ``;
  }

  public clearData(): void {
    const newCenter: TCenter = buildCenterMock({});
    this.existingCenterSearch = ``;
    this.centerControl.setValue(null);
    this.onCenterSelectCallback(newCenter, true);
  }

  onCustomMetricFormValidityChange(isValid: boolean) {
    this.customMetricsFormValidityChange.emit(isValid);
  }

  onCustomMetricFormValueChange(value: any) {
    this.customMetricsFormValueChange.emit(value);
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._centersService.resetCenters();
    this.centerData = {
      ...buildCenterMock({}),
      projectsData: [],
    };
  }

  private _filter(value: string = ``): Observable<TCenter[]> {
    this.isLoading = true;
    return this._centersService.getCenterByName(value, ``).pipe(
      map((response) => {
        this.isLoading = false;
        return response;
      })
    );
  }

  private _setCenterOptions(): void {
    this.centerControl = new FormControl();
    this.filteredOptions = this.centerControl.valueChanges.pipe(
      startWith(``),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((value: string | null | TCenter) => {
        const query = typeof value === `string` ? value : ``;
        return query ? this._filter(query as string) : [];
      })
    );
  }

  private isCenterSelected(): boolean {
    return this.centerData.c_id !== `` && this.centerParams.centerId !== ``;
  }
}
