import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TTreatment } from '@shared/interfaces/TProject';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-treatment-options',
  templateUrl: './treatment-options.component.html',
  styleUrls: ['./treatment-options.component.scss'],
})
export class TreatmentOptionsComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TreatmentOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
  }
  public params: TBaseSettingsTabParams;

  public newTreatmentOptionData: TTreatment = {
    description: ``,
    name: ``,
  };

  public getTreatmentOptions(): TTreatment[] {
    return this.params.selectedProject?.treatment_options ?? [];
  }

  public add() {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.treatment_options === undefined
    ) {
      this.params.selectedProject.treatment_options = [];
      this.params.selectedProject?.treatment_options.push(
        structuredClone(this.newTreatmentOptionData)
      );
    } else {
      this.params.selectedProject?.treatment_options.push(
        structuredClone(this.newTreatmentOptionData)
      );
    }
  }

  private _isNameDuplicated(
    treatment_options: TTreatment[] | undefined,
    name: string
  ): boolean {
    return (treatment_options ?? []).map((r) => r.name).includes(name);
  }

  public addAvailable(): boolean {
    const formIsValid =
      this.newTreatmentOptionData.name !== `` &&
      this.newTreatmentOptionData.description !== ``;
    const belowLimit = this.params.selectedProject?.treatment_options
      ? this.params.selectedProject?.treatment_options.length < 9
      : true;
    return (
      formIsValid &&
      !this._isNameDuplicated(
        this.params.selectedProject?.treatment_options,
        this.newTreatmentOptionData.name
      ) &&
      belowLimit
    );
  }

  public remove(region: TTreatment): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions1 !== undefined
    ) {
      const idx: number =
        this.params.selectedProject.treatment_options.findIndex(
          (r) => r.name === region.name
        );
      this.params.selectedProject.treatment_options.splice(idx, 1);
    }
  }

  ngOnInit(): void {}
}
