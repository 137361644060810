<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">Treatment Goals</h2>
  <div>
    <app-treatment-item [TData]="newTreatmentGoalData"></app-treatment-item>
    <button
      mat-flat-button
      color="primary"
      (click)="add()"
      [disabled]="!addAvailable()"
    >
      <span>Add</span>
    </button>
  </div>
  <div *ngIf="getTreatmentGoals().length > 0">
    <h3 class="my-4">Existing goals</h3>
    <div *ngFor="let goal of getTreatmentGoals()">
      <app-treatment-item [TData]="goal" [handleDelete]="remove.bind(this)">
      </app-treatment-item>
    </div>
  </div>
</div>
