import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { TCategory } from '@shared/interfaces/TCategory';
import { TFieldNameMappingItem } from '@shared/interfaces/TFieldNameMappingItem';
import {
  Operator,
  TProductUseScoreItem,
  TProject,
  TScoreItem,
} from '@shared/interfaces/TProject';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import {
  ConfirmDialogData,
  ConfirmationDialogComponent,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { CacheService } from 'src/app/services/CacheService';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { HCPService } from 'src/app/services/HCPService';
import { LoadingService } from 'src/app/services/LoadingService';
import { RiseAppApiService } from 'src/app/services/RiseAppApiService';
import { DataProcessorService } from '../../services/DataProcessorService';
import {
  TProjectReviewParams,
  projectReviewParamsValidator,
} from '../project-review.component';
import {
  AVAILABLE_CENTERS_FIELDS,
  AVAILABLE_CENTER_POTENTIAL_FIELDS,
  AVAILABLE_CENTER_UTIL_FIELDS,
  AVAILABLE_CT_FIELDS,
  AVAILABLE_ENGAGEMENT_FIELDS,
  AVAILABLE_INFLUENCE_FIELDS,
  AVAILABLE_RISING_STAR_FIELDS,
  AVAILABLE_VALUE_FIELDS,
  BOOLEAN_OPERATORS,
  MATHEMATICAL_OPERATORS,
  ScoreArea,
  buildCenterScoreItem,
  buildHCPScoreItem,
} from './Constants';
import {
  BaseSettingsComponent,
  TBaseSettingsParams,
} from './base-settings/base-settings.component';
import { BatchUpdateComponent } from './batch-update/batch-update.component';
import {
  CenterFieldsComponent,
  TFieldsNamingParams,
} from './center-fields/center-fields.component';
import { FiltersComponent } from './filters/filters.component';
import {
  ProductUseScoringComponent,
  TProductUseScoreSettingsParams,
} from './product-use-scoring/product-use-scoring.component';
import { ProximityComponent } from './proximity/proximity.component';
import { RegionsComponent } from './regions/regions.component';
import {
  ScoreSettingsType,
  ScoringSettingsComponent,
  TBaseScoreItem,
  TScoreSettingsParams,
  TScoreSettingsWithCutoffParams,
} from './scoring-settings/scoring-settings.component';
import { TagsSettingsComponent } from './tags-settings/tags-settings.component';
import { TierDefinitionsComponent } from './tier-definitions/tier-definitions.component';
import { TreatmentGoalsComponent } from './treatment-goals/treatment-goals.component';
import { TreatmentOptionsComponent } from './treatment-options/treatment-options.component';
import { TreatmentUsesComponent } from './treatment-uses/treatment-uses.component';

export interface TProjectSettingsParams {
  selectedCategory: TCategory | undefined;
  selectedProject: TProject | undefined;
  closeSettings: () => void;
  isPinned: boolean;
}

export interface TBaseSettingsTabParams {
  selectedCategory: TCategory | undefined;
  selectedProject: TProject | undefined;
}

interface TSettingsItem {
  icon: string;
  name: string;
  description: string;
  openCallback: () => void;
}

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  public isPinned: boolean = false;
  public selectedHCPInfluenceFields: TBaseScoreItem[] = [];
  public selectedHCPEngagementFields: TBaseScoreItem[] = [];
  public selectedHCPScoreFields: TBaseScoreItem[] = [];
  public selectedCentresScoreFields: TBaseScoreItem[] = [];
  public selectedCentrePotentialScoreFields: TBaseScoreItem[] = [];
  public selectedCentreUtilScoreFields: TBaseScoreItem[] = [];

  public selectedCTScoreFields: TBaseScoreItem[] = [];
  public selectedRisingStarScoreFields: TBaseScoreItem[] = [];
  public selectedProductUseFields: TProductUseScoreItem[] = [];
  public selectedRisingStarCutoff: number = 0;

  @Input()
  public params: TProjectSettingsParams = {
    closeSettings: () => void 0,
    selectedCategory: undefined,
    selectedProject: undefined,
    isPinned: false,
  };
  @Input()
  public centerFieldsNames: TFieldNameMappingItem[] = [];
  @Input()
  public hcpFieldsNames: TFieldNameMappingItem[] = [];
  @ViewChild('tabGroup')
  public tabGroup: MatTabGroup;
  public availableInfluenceFields: TBaseScoreItem[] =
    AVAILABLE_INFLUENCE_FIELDS;
  public availableEngagementFields: TBaseScoreItem[] =
    AVAILABLE_ENGAGEMENT_FIELDS;
  public availableHCPScoreFields: TBaseScoreItem[] = AVAILABLE_VALUE_FIELDS;
  public availableCentersFields: TBaseScoreItem[] = AVAILABLE_CENTERS_FIELDS;
  public availableCenterPotentialFields: TBaseScoreItem[] =
    AVAILABLE_CENTER_POTENTIAL_FIELDS;
  public availableCenterUtilFields: TBaseScoreItem[] =
    AVAILABLE_CENTER_UTIL_FIELDS;
  public availableCTFields: TBaseScoreItem[] = AVAILABLE_CT_FIELDS;
  public availableRisingStarFields: TBaseScoreItem[] =
    AVAILABLE_RISING_STAR_FIELDS;
  public availableProductUseFields: TBaseScoreItem[] = [];
  public searchTerm: string = ``;
  public pageNumber: number = 1;
  public pageSize: number = 10;
  public sortBy: string = `first_name,1`;
  @ViewChild(`projectFileInput`)
  public importInput: ElementRef;
  public settingsItems: TSettingsItem[] = [
    {
      description: `Build project based on Pubmed articles`,
      icon: `sync`,
      name: `Process data`,
      openCallback: () => this.processData(),
    },
    {
      description: `Date range, data cleanup`,
      icon: `settings_suggest`,
      name: `Settings`,
      openCallback: () => this._openSettingsDialog(),
    },
    {
      description: `Account specific center field namings`,
      icon: `description`,
      name: `Field namings`,
      openCallback: () => this._openNamingsDialog(),
    },
    {
      description: `Scores configuration for HCP influence`,
      icon: `show_chart`,
      name: `Influence score`,
      openCallback: () => this._openInfluenceScoreDialog(),
    },
    {
      description: `Scores configuration for product use`,
      icon: `show_chart`,
      name: `Product Use score`,
      openCallback: () => this._openProductUseScoreDialog(),
    },
    {
      description: `Scores configuration for HCP engagement`,
      icon: `show_chart`,
      name: `Engagement score`,
      openCallback: () => this._openEngagementScoreDialog(),
    },
    {
      description: `Scores configuration for HCP value`,
      icon: `show_chart`,
      name: `Value score`,
      openCallback: () => this._openValueScoreDialog(),
    },
    {
      description: `Scores configuration for Center Impact`,
      icon: `show_chart`,
      name: `Centers Impact score`,
      openCallback: () => this._openCentersScoreDialog(),
    },
    {
      description: `Scores configuration for Center potential`,
      icon: `show_chart`,
      name: `Centers Potential score`,
      openCallback: () => this._openCenterPotentialScoreDialog(),
    },
    {
      description: `Scores configuration for Center Utilisation`,
      icon: `show_chart`,
      name: `Centers Utilisation score`,
      openCallback: () => this._openCenterUtilScoreDialog(),
    },
    {
      description: `Scores configuration for center's clinical trials`,
      icon: `show_chart`,
      name: `Clinical trials score`,
      openCallback: () => this._openCentersClinicalTrialsScoreDialog(),
    },
    {
      description: `Scores configuration for Rising Star`,
      icon: `show_chart`,
      name: `Rising Star score`,
      openCallback: () => this._openRisingStarScoreDialog(),
    },
    {
      description: `Sales data specific for region`,
      icon: `public`,
      name: `Regions`,
      openCallback: () => this._openRegionsScoreDialog(),
    },
    {
      description: `Treatment Goals`,
      icon: `medical_services`,
      name: `Treatment Goals`,
      openCallback: () => this._openTreatmentGoalsDialog(),
    },
    {
      description: `Treatment Options`,
      icon: `medical_services`,
      name: `Treatment Options`,
      openCallback: () => this._openTreatmentOptionsDialog(),
    },
    {
      description: `Patient Profile Treated`,
      icon: `medical_services`,
      name: `Patient Profile Treated`,
      openCallback: () => this._openPatientProfileTreatedDialog(),
    },
    {
      description: `Tiers definitions for HCPs and Centers`,
      icon: `sort`,
      name: `Tiers`,
      openCallback: () => this._openTiersScoreDialog(),
    },
    {
      description: `Tags definitions for HCPs and Centers`,
      icon: `cloud_queue`,
      name: `Tags`,
      openCallback: () => this._openTagsScoreDialog(),
    },
    {
      description: `Filter not important data to reduce calculation time`,
      icon: `filter_list`,
      name: `Filters`,
      openCallback: () => this._openFiltersScoreDialog(),
    },
    {
      description: `Configure weights for proximity`,
      icon: `scatter_plot`,
      name: `Proximity`,
      openCallback: () => this.openProximitySettingsDialog(),
    },
    {
      description: `Export project`,
      icon: `settings_applications`,
      name: `Export project`,
      openCallback: () => this.handleExportProject(),
    },
    {
      description: `Import project`,
      icon: `settings_applications`,
      name: `Import`,
      openCallback: () => {
        this.importInput.nativeElement.click();
      },
    },
    {
      description: `Recalculate Centers scores and indexes`,
      icon: `new_releases`,
      name: `Center scores`,
      openCallback: () => {
        this._recalculateCenterScores();
      },
    },
    {
      description: `Recalculate HCPs scores and indexes`,
      icon: `new_releases`,
      name: `HCP scores`,
      openCallback: () => {
        this._recalculateHCPsScores();
      },
    },
    {
      description: `Update multiple entities based on search criteria`,
      icon: `update`,
      name: `Batch update`,
      openCallback: () => {
        this._openBatchUpdateDialog();
      },
    },
  ];

  private _subs: Subscription[] = [];
  private projectReviewParams: TProjectReviewParams =
    projectReviewParamsValidator({});

  constructor(
    public settingsDialog: MatDialog,
    public namingsDialog: MatDialog,
    public influenceScoreDialog: MatDialog,
    public engagementScoreDialog: MatDialog,
    public productUseScoreDialog: MatDialog,
    public valueScoreDialog: MatDialog,
    public centerScoreDialog: MatDialog,
    public centerUtilScoreDialog: MatDialog,
    public centerPotentialScoreDialog: MatDialog,
    public clinicalTrialsScoreDialog: MatDialog,
    public risingStarScoreDialog: MatDialog,
    public regionsDialog: MatDialog,
    public treatGoalsDialog: MatDialog,
    public treatOptionsDialog: MatDialog,
    public treatUsesDialog: MatDialog,
    public filterDialog: MatDialog,
    public tiersDialog: MatDialog,
    public tagsDialog: MatDialog,
    public deleteConfirmationDialog: MatDialog,
    public proximityDialog: MatDialog,
    public importSettingsDialog: MatDialog,
    private _route: ActivatedRoute,
    private _categoriesService: CategoriesService,
    private _router: Router,
    private _loadingService: LoadingService,
    private _riseApi: RiseAppApiService,
    private _dataProcessorService: DataProcessorService,
    private _HCPsService: HCPService,
    private _cacheService: CacheService
  ) {
    this._subs.push(
      this._route.params.subscribe(async (params: any) => {
        this.projectReviewParams = projectReviewParamsValidator(params);
      })
    );
    this._subs.push(
      this._categoriesService.getCategoriesObservable().subscribe(() => {
        this.params.selectedProject = this._categoriesService.getProject(
          this.projectReviewParams.projectId
        );
        if (!isNullOrUndefined(this.params.selectedProject)) {
          this._processAvailableFields(this.params.selectedProject);
        }
        this._setExistingScores();
        this._setExistingFieldsNames();
      })
    );
    this._riseApi.setLogProjects((updateCounts: boolean) => {
      if (updateCounts) {
        const projectId: string = this.params.selectedProject?.id ?? ``;
        if (!isNullOrEmpty(projectId)) {
          this._dataProcessorService.updateProjectCounts(projectId);
        }
        console.log(updateCounts);
      }
      console.log(this.params.selectedProject);
      return JSON.stringify(this.params.selectedProject);
    });
  }

  public handleExportProject(): void {
    if (isNullOrUndefined(this.params.selectedProject)) {
      this._loadingService.triggerErrorMessage(`No project to save`);
    } else {
      const a: HTMLAnchorElement = document.createElement(`a`);
      const projectData: Blob = new Blob(
        [JSON.stringify(this.params.selectedProject)],
        { type: `text/plain` }
      );
      a.href = URL.createObjectURL(projectData);
      a.download = this.params.selectedProject.name;
      a.click();
    }
  }

  public handleImportProject(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    void files
      ?.item(0)
      ?.text()
      .then((res) => {
        //TODO validate res
        const importedProject: TProject = JSON.parse(res);
        const existingProject: TProject | undefined =
          this._categoriesService.getProject(importedProject.id);
        let confirmationMessage: string = `Project ${importedProject.name} found and has been imported`;
        this._categoriesService.updateProject(
          this.params.selectedCategory?.categoryId ?? ``,
          importedProject,
          () => {
            if (isNullOrUndefined(existingProject)) {
              confirmationMessage = `Project ${importedProject.name} not found and has been added`;
            }
            this._loadingService.triggerOkMessage(confirmationMessage, 5000);
          }
        );
      });
  }

  public async processData(): Promise<void> {
    if (
      !isNullOrUndefined(this.params.selectedCategory) &&
      !isNullOrUndefined(this.params.selectedProject)
    ) {
      this._loadingService.setLogVisible(true);
      this._dataProcessorService.processData(
        this.params.selectedCategory?.categoryId,
        this.params.selectedProject.id
      );
    }
  }

  public openProximitySettingsDialog(): void {
    this.proximityDialog.open<ProximityComponent, TBaseSettingsTabParams>(
      ProximityComponent,
      {
        data: this.params,
      }
    );
  }

  public ngOnInit(): void {
    this.isPinned = this.params.isPinned;
    this._setExistingScores();
  }

  public ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  public handleIsPinnedChange(): void {
    if (
      this.params.selectedCategory !== undefined &&
      this.params.selectedProject !== undefined
    ) {
      const project: TProject = this.params.selectedProject;
      project.isPinned = this.isPinned;
      this._categoriesService.updateProject(
        this.params.selectedCategory.categoryId,
        project
      );
    }
  }

  public save(
    recalculate: boolean = false,
    shouldRecalculateProjectByDate: boolean = false
  ): void {
    // Note - recalculate on left is triggered by user, on right - forced automatically and it is not the same
    if (
      this.params.selectedCategory !== undefined &&
      this.params.selectedProject !== undefined
    ) {
      const influenceWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedHCPInfluenceFields
      );
      const engagementWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedHCPEngagementFields
      );
      const valueWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedHCPScoreFields
      );
      const centresWights: TScoreItem[] = this._mapToScoreItem(
        this.selectedCentresScoreFields
      );
      const centresPotentialWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedCentrePotentialScoreFields
      );
      const centresUtilWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedCentreUtilScoreFields
      );
      const clinicalTrialsWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedCTScoreFields
      );
      const risingStarWeights: TScoreItem[] = this._mapToScoreItem(
        this.selectedRisingStarScoreFields
      );
      const productUse: TProductUseScoreItem[] = this.selectedProductUseFields;

      this._categoriesService.updateProject(
        this.params.selectedCategory.categoryId,
        {
          ...this.params.selectedProject,
          scores: {
            center: centresWights,
            centerPotential: centresPotentialWeights,
            centerUtil: centresUtilWeights,
            clinicalTrials: clinicalTrialsWeights,
            hcpEngagement: engagementWeights,
            hcpInfluence: influenceWeights,
            hcpValue: valueWeights,
            risingStar: risingStarWeights,
            hcpProductUse: productUse,
            risingStarCutoff: this.selectedRisingStarCutoff,
          },
        },
        () => {
          this._loadingService.triggerOkMessage(`Project updated`);
          this._updateCache(this.params.selectedProject!.id);
          if (recalculate) {
            this._categoriesService.updateCenterScores({
              categoryId: this.params.selectedCategory!.categoryId,
              projectId: this.params.selectedProject!.id,
              callback: () => {
                this._loadingService.triggerOkMessage(`Center scores updated`);
                this._categoriesService.updateHCPsScores({
                  categoryId: this.params.selectedCategory!.categoryId,
                  projectId: this.params.selectedProject!.id,
                  callback: () =>
                    this._loadingService.triggerOkMessage(
                      `HCPs scores updated`
                    ),
                  recalculateTotals: false,
                });
              },
            });
          }
        },
        shouldRecalculateProjectByDate
      );
    }
  }

  private _mapToScoreItem(items: TBaseScoreItem[]): TScoreItem[] {
    return items.map((item) => {
      return {
        fieldId: item.fieldId,
        operator: item.operator,
        score: item.score,
      };
    });
  }

  private _updateCache(projectId: string): void {
    this._subs.push(
      this._HCPsService.getCTForProject(projectId, true).subscribe((res) => {
        this._cacheService.setCTItem(projectId, res.studies, []);
      })
    );
  }

  public getBaseSettingsTabParams(): TBaseSettingsTabParams {
    return {
      selectedCategory: this.params.selectedCategory,
      selectedProject: this.params.selectedProject,
    };
  }

  public deleteProject(): void {
    if (
      this.params.selectedCategory !== undefined &&
      this.params.selectedProject !== undefined
    ) {
      const message = `Are you sure you want to delete ${this.params.selectedProject.name} project?`;
      const dialogData: ConfirmDialogData = {
        title: `Delete project`,
        message,
      };
      const dialogRef = this.deleteConfirmationDialog.open(
        ConfirmationDialogComponent,
        {
          maxWidth: `400px`,
          data: dialogData,
        }
      );

      dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this._categoriesService.removeProject(
            this.params.selectedCategory!.categoryId,
            this.params.selectedProject!.id,
            () => {
              this._categoriesService.setSelectedProject(``);
              this._router.navigate([`/${ROUTES_PATHS.dashboard}`], {
                relativeTo: this._route,
              });
            }
          );
        }
      });
    }
  }

  private _scoreItemMapper(field: TScoreItem, area: ScoreArea): TBaseScoreItem {
    if (area === `center`) {
      if (
        [Operator.BooleanFalse, Operator.BooleanTrue].includes(field.operator)
      ) {
        return buildCenterScoreItem(
          field.fieldId,
          field.operator,
          BOOLEAN_OPERATORS,
          area,
          field.score
        );
      } else {
        return buildCenterScoreItem(
          field.fieldId,
          field.operator,
          MATHEMATICAL_OPERATORS,
          area,
          field.score
        );
      }
    }
    const operators = [Operator.BooleanFalse, Operator.BooleanTrue].includes(
      field.operator
    )
      ? BOOLEAN_OPERATORS
      : MATHEMATICAL_OPERATORS;
    return buildHCPScoreItem(
      field.fieldId,
      field.operator,
      operators,
      area,
      field.score
    );
  }

  private _setExistingScores(): void {
    let project: TProject | undefined = this.params.selectedProject;
    if (!isNullOrUndefined(project) && !isNullOrUndefined(project.scores)) {
      const sc = project.scores;
      this.selectedHCPInfluenceFields = this._scoreMap(
        sc.hcpInfluence,
        'hcpInfluence'
      );
      this.selectedHCPEngagementFields = this._scoreMap(
        sc.hcpEngagement,
        'hcpEngagement'
      );
      this.selectedHCPScoreFields = this._scoreMap(sc.hcpValue, 'hcpValue');
      this.selectedCentresScoreFields = this._scoreMap(sc.center, 'center');
      this.selectedCTScoreFields = this._scoreMap(
        sc.clinicalTrials,
        'clinicalTrials'
      );
      this.selectedRisingStarScoreFields = this._scoreMap(
        sc.risingStar,
        'risingStar'
      );
      this.selectedProductUseFields = sc.hcpProductUse ?? [];
      this.selectedRisingStarCutoff = sc?.risingStarCutoff ?? 0;
      this._processAvailableFields(project);
    }
  }

  private _scoreMap(scores: TScoreItem[], area: ScoreArea): TBaseScoreItem[] {
    return (scores ?? []).map((item) => this._scoreItemMapper(item, area));
  }

  private _setExistingFieldsNames(): void {
    let project: TProject | undefined = this.params.selectedProject;
    if (!isNullOrUndefined(project)) {
      const centersNamingsFromProjectsData: TFieldNameMappingItem[] =
        project?.centerFields ?? [];
      const defaultCenterNamings: TFieldNameMappingItem[] = Array.from(
        CENTER_DB_MAPPINGS.values()
      )
        .filter((f) => f.isEditable)
        .map((f) => {
          return {
            fieldName: f.fieldId,
            filedViewValue: f.name,
          };
        });
      project.centerFields = defaultCenterNamings.map((it) => {
        const existingItem: TFieldNameMappingItem | undefined =
          centersNamingsFromProjectsData.find(
            (c) => c.fieldName === it.fieldName
          );
        if (isNullOrUndefined(existingItem)) {
          return it;
        } else {
          return existingItem;
        }
      });
      const HCPNamingsFromProjectsData: TFieldNameMappingItem[] =
        project?.hcpFields ?? [];
      const defaultHCPNamings: TFieldNameMappingItem[] = Array.from(
        HCP_DB_MAPPINGS.values()
      )
        .filter((f) => f.isEditable)
        .map((f) => {
          return {
            fieldName: f.fieldId,
            filedViewValue: f.name,
          };
        });
      project.hcpFields = defaultHCPNamings.map((it) => {
        const existingItem: TFieldNameMappingItem | undefined =
          HCPNamingsFromProjectsData.find((c) => c.fieldName === it.fieldName);
        if (isNullOrUndefined(existingItem)) {
          return it;
        } else {
          return existingItem;
        }
      });
      this.centerFieldsNames = project.centerFields;
      this.hcpFieldsNames = project.hcpFields;
    }
  }

  private _processAvailableFields(project: TProject): void {
    (project?.centerFields ?? []).forEach((field) => {
      [
        ...this.availableEngagementFields,
        ...this.selectedHCPEngagementFields,
        ...this.availableCentersFields,
        ...this.availableCTFields,
        ...this.selectedCentresScoreFields,
        ...this.selectedCTScoreFields,
        ...this.availableHCPScoreFields,
        ...this.selectedHCPScoreFields,
        ...this.availableInfluenceFields,
        ...this.selectedHCPInfluenceFields,
        ...this.selectedRisingStarScoreFields,
      ].forEach((f) => {
        if (f.fieldId === field.fieldName) {
          f.fieldName = field.filedViewValue;
        }
      });
    });
  }

  private _openNamingsDialog(): void {
    this.namingsDialog.open<CenterFieldsComponent, TFieldsNamingParams>(
      CenterFieldsComponent,
      {
        data: {
          params: this.params,
          centerFieldsNames: this.centerFieldsNames,
        },
      }
    );
  }

  private _openSettingsDialog(): void {
    const data: TBaseSettingsParams = {
      applyCallback: (recalculate, shouldRecalculateProjectByDate) =>
        this.save(recalculate, shouldRecalculateProjectByDate),
      minProjectYear: this.params.selectedProject?.startYear ?? 0,
      params: this.params,
      selectedMinProjectYear: this.params.selectedProject?.selectedMinYear ?? 0,
      clinicalTrialsKeywords:
        this.params.selectedProject?.clinicalTrialsKeywords ?? [],
    };
    this.settingsDialog.open<BaseSettingsComponent, TBaseSettingsParams>(
      BaseSettingsComponent,
      {
        height: `750px`,
        data: data,
      }
    );
  }

  private _openInfluenceScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableInfluenceFields,
      params: this.params,
      selectedItems: this.selectedHCPInfluenceFields,
      name: `Influence scores`,
      scoreArea: `hcpInfluence`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.influenceScoreDialog.open<
      ScoringSettingsComponent,
      TScoreSettingsParams
    >(ScoringSettingsComponent, {
      data: data,
    });
  }

  private _openEngagementScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableEngagementFields,
      params: this.params,
      selectedItems: this.selectedHCPEngagementFields,
      name: `Engagement scores`,
      scoreArea: `hcpEngagement`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.engagementScoreDialog.open<
      ScoringSettingsComponent,
      TScoreSettingsParams
    >(ScoringSettingsComponent, {
      data: data,
    });
  }

  private _openProductUseScoreDialog(): void {
    const data: TProductUseScoreSettingsParams = {
      params: this.params,
      selectedItems: this.selectedProductUseFields,
      scoreArea: `hcpProductUse`,
      __type: ScoreSettingsType.Base,
    };
    this.productUseScoreDialog.open<
      ProductUseScoringComponent,
      TProductUseScoreSettingsParams
    >(ProductUseScoringComponent, {
      data: data,
    });
  }

  private _openValueScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableHCPScoreFields,
      params: this.params,
      selectedItems: this.selectedHCPScoreFields,
      name: `Value scores`,
      scoreArea: `hcpValue`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.valueScoreDialog.open<ScoringSettingsComponent, TScoreSettingsParams>(
      ScoringSettingsComponent,
      {
        data: data,
      }
    );
  }

  private _openCentersScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableCentersFields,
      params: this.params,
      selectedItems: this.selectedCentresScoreFields,
      name: `Centers scores`,
      scoreArea: `center`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.centerScoreDialog.open<ScoringSettingsComponent, TScoreSettingsParams>(
      ScoringSettingsComponent,
      {
        data: data,
      }
    );
  }

  private _openCenterPotentialScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableCenterPotentialFields,
      params: this.params,
      selectedItems: this.selectedCentrePotentialScoreFields,
      name: `Centers potential scores`,
      scoreArea: `center`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.centerPotentialScoreDialog.open<
      ScoringSettingsComponent,
      TScoreSettingsParams
    >(ScoringSettingsComponent, {
      data: data,
    });
  }

  private _openCenterUtilScoreDialog(): void {
    const data: TScoreSettingsParams = {
      availableFields: this.availableCenterUtilFields,
      params: this.params,
      selectedItems: this.selectedCentreUtilScoreFields,
      name: `Centers utilisation scores`,
      scoreArea: `center`,
      __type: ScoreSettingsType.Base,
      tiersAvailable: true,
    };
    this.centerUtilScoreDialog.open<
      ScoringSettingsComponent,
      TScoreSettingsParams
    >(ScoringSettingsComponent, {
      data: data,
    });
  }

  private _openCentersClinicalTrialsScoreDialog(): void {
    const data: TScoreSettingsParams = {
      __type: ScoreSettingsType.Base,
      availableFields: this.availableCTFields,
      params: this.params,
      selectedItems: this.selectedCTScoreFields,
      name: `Clinical trials scores`,
      scoreArea: `clinicalTrials`,
      tiersAvailable: false,
    };
    this.clinicalTrialsScoreDialog.open<
      ScoringSettingsComponent,
      TScoreSettingsParams
    >(ScoringSettingsComponent, {
      data: data,
    });
  }

  private _openRisingStarScoreDialog(): void {
    const data: TScoreSettingsWithCutoffParams = {
      __type: ScoreSettingsType.Cutoff,
      availableFields: this.availableRisingStarFields,
      params: this.params,
      selectedItems: this.selectedRisingStarScoreFields,
      name: `Rising Star scores`,
      scoreArea: `risingStar`,
      showCutoff: true,
      cutoff: this.selectedRisingStarCutoff,
      tiersAvailable: true,
    };
    this.risingStarScoreDialog
      .open<ScoringSettingsComponent, TScoreSettingsParams>(
        ScoringSettingsComponent,
        {
          data: data,
          disableClose: true,
        }
      )
      .afterClosed()
      .subscribe((res: number) => {
        this.selectedRisingStarCutoff = res;
      });
  }

  private _openRegionsScoreDialog(): void {
    this.regionsDialog.open<RegionsComponent, TBaseSettingsTabParams>(
      RegionsComponent,
      {
        data: this.params,
      }
    );
  }

  private _openTreatmentGoalsDialog(): void {
    this.treatGoalsDialog.open<TreatmentGoalsComponent, TBaseSettingsTabParams>(
      TreatmentGoalsComponent,
      {
        data: this.params,
      }
    );
  }

  private _openTreatmentOptionsDialog(): void {
    this.treatOptionsDialog.open<
      TreatmentOptionsComponent,
      TBaseSettingsTabParams
    >(TreatmentOptionsComponent, {
      data: this.params,
    });
  }

  private _openPatientProfileTreatedDialog(): void {
    this.treatUsesDialog.open<TreatmentUsesComponent, TBaseSettingsTabParams>(
      TreatmentUsesComponent,
      {
        data: this.params,
      }
    );
  }

  private _openTiersScoreDialog(): void {
    this.tiersDialog.open<TierDefinitionsComponent, TBaseSettingsTabParams>(
      TierDefinitionsComponent,
      {
        data: this.params,
      }
    );
  }

  private _openTagsScoreDialog(): void {
    this.tagsDialog.open<TagsSettingsComponent, TBaseSettingsTabParams>(
      TagsSettingsComponent,
      {
        data: this.params,
      }
    );
  }

  private _openFiltersScoreDialog(): void {
    this.filterDialog.open<FiltersComponent, TBaseSettingsTabParams>(
      FiltersComponent,
      {
        data: this.params,
      }
    );
  }

  private _recalculateCenterScores(): void {
    this._categoriesService.updateCenterScores({
      categoryId: this.params.selectedCategory!.categoryId,
      projectId: this.params.selectedProject!.id,
      callback: () => {
        this._loadingService.triggerOkMessage(`Center scores updated`);
      },
      recalculateTotals: false,
    });
  }

  private _recalculateHCPsScores(): void {
    this._categoriesService.updateHCPsScores({
      categoryId: this.params.selectedCategory!.categoryId,
      projectId: this.params.selectedProject!.id,
      callback: () => {
        this._loadingService.triggerOkMessage(`HCPs scores updated`);
      },
      recalculateTotals: false,
    });
  }

  private _openBatchUpdateDialog(): void {
    this.filterDialog.open<BatchUpdateComponent, TBaseSettingsTabParams>(
      BatchUpdateComponent,
      {
        data: this.params,
      }
    );
  }
}
