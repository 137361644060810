<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">New region 1</h2>
  <div>
    <app-region-item [regionData]="newRegionData1"></app-region-item>
    <button
      mat-flat-button
      color="primary"
      (click)="add1()"
      [disabled]="!addAvailable1()"
    >
      <span>Add</span>
    </button>
  </div>
  <div *ngIf="getRegions1().length > 0">
    <h3 class="my-4">Existing regions</h3>
    <div *ngFor="let region1 of getRegions1()">
      <app-region-item
        [loadPDF]="loadPDF.bind(this)"
        [regionData]="region1"
        [handleDeleteRegion]="remove1.bind(this)"
      >
      </app-region-item>
    </div>
  </div>
  <div class="mt-4 mat-h3" [ngClass]="checkIfPDFViewerVisible()">
    <app-sales-data-wrapper [pdfSrc]="pdfSrc"></app-sales-data-wrapper>
  </div>
  <h2 class="my-4 mat-h2">New region 2</h2>
  <div>
    <app-region-item [regionData]="newRegionData2"></app-region-item>
    <button
      mat-flat-button
      color="primary"
      (click)="add2()"
      [disabled]="!addAvailable2()"
    >
      <span>Add</span>
    </button>
  </div>
  <div *ngIf="getRegions2().length > 0">
    <h3 class="my-4">Existing regions</h3>
    <div *ngFor="let region2 of getRegions2()">
      <app-region-item
        [loadPDF]="loadPDF.bind(this)"
        [regionData]="region2"
        [handleDeleteRegion]="remove2.bind(this)"
      >
      </app-region-item>
    </div>
  </div>
  <div class="mt-4 mat-h3" [ngClass]="checkIfPDFViewerVisible()">
    <app-sales-data-wrapper [pdfSrc]="pdfSrc"></app-sales-data-wrapper>
  </div>
</div>
