import { TProductSales } from '@shared/interfaces/TProductUse';

export const sortedSales = (sales: TProductSales[]): TProductSales[] => {
  function parseTimePeriod(timePeriod: string): {
    year: number;
    month: number;
  } {
    // "M012024" -> 'M' at index 0
    // month = substring(1, 3) => "01"
    // year  = substring(3)   => "2024"
    const month = parseInt(timePeriod.substring(1, 3), 10);
    const year = parseInt(timePeriod.substring(3), 10);
    return { year, month };
  }

  // Sort the data from earliest to latest
  return sales.sort((a, b) => {
    const aParsed = parseTimePeriod(a.time_period);
    const bParsed = parseTimePeriod(b.time_period);

    // First compare by year
    if (aParsed.year !== bParsed.year) {
      return aParsed.year - bParsed.year;
    }

    // If same year, compare by month
    return aParsed.month - bParsed.month;
  });
};
