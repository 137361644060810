<div class="flex gap-4">
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="TData.name" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="TData.description" required />
  </mat-form-field>
  <button
    *ngIf="deleteVisible()"
    mat-icon-button
    (click)="deleteItem()"
    aria-label="Delete"
    color="error"
  >
    <mat-icon>delete_forever</mat-icon>
  </button>
</div>
