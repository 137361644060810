<div class="inline-flex flex-wrap gap-4 mb-4">
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_impact_tier") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_impact_tier"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_impact_tier')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_bu1_potential1") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_bu1_potential1"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_bu1_potential1')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_bu1_potential2") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_bu1_potential2"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_bu1_potential2')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_bu1_clin1") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_bu1_clin1"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_bu1_clin1')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_bu1_use1") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_bu1_use1"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_bu1_use1')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="no-margin">
    <mat-label>{{ getFieldDescription("c_proj_bu1_use2") }}</mat-label>
    <mat-select
      [(value)]="getProjectData().c_proj_bu1_use2"
      [disabled]="fieldsDisabled"
    >
      <mat-option
        *ngFor="let tier of getTiers('c_proj_bu1_use2')"
        [value]="tier.name"
        >{{ tier.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
