import { SharedConstants } from '@shared/Constants';
import { PATH_DELIMITER, TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';

export const CENTER_DB_MAPPINGS: Map<string, TDBFieldMappingItem> = new Map([
  [
    `actions`,
    {
      columnWidth: 70,
      fieldId: `actions`,
      name: `Actions`,
      isSortable: false,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInRaport: true
    }
  ],
  [
    `c_id`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_id`,
      name: `ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_name`,
    {
      columnWidth: 350,
      fieldId: `c_name`,
      name: `Name`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true,
      visibleInRaport: true
    }
  ],
  [
    `c_original_name`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_original_name`,
      name: `Original name`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_city`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_city`,
      name: `City`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `c_state`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_state`,
      name: `State`,
      isSortable: true,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true,
      usOnly: true,
      isReportFilterField: true,
      type: `string`,
      availableInTransformation: true
    }
  ],
  [
    `c_country`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_country`,
      name: `Country`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true,
      visibleInRaport: true,
      isReportFilterField: true
    }
  ],
  [
    `c_project_region1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_project_region1`,
      name: `Region 1`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isRegionField: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}c_project_region1`,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `c_project_region2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_project_region2`,
      name: `Region 2`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isRegionField: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}c_project_region2`,
      isReportFilterField: true,
      type: `string`
    }
  ],
  [
    `c_project_reference`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_project_reference`,
      name: `Account reference`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      tierField: false,
      export: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}c_project_reference`,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `c_beds`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_beds`,
      name: `Beds`,
      isSortable: true,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      tierField: false,
      export: true,
      visibleInRaport: true,
      isReportFilterField: true,
      type: `number`,
      availableInTransformation: true
    }
  ],
  [
    `c_beds_ICU`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_beds_ICU`,
      name: `Beds ICU`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_for_profit`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_for_profit`,
      name: `For profit`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `boolean`
    }
  ],
  [
    `c_340b`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_340b`,
      name: `340b`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_comment`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_comment`,
      name: `Comment`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_email`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_email`,
      name: `eMail`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `c_address`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_address`,
      name: `Address`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      visibleInMerge: true,
      export: true
    }
  ],
  [
    `c_sales_data_filename`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_sales_data_filename`,
      name: `Sales data filename`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `articleId`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `articleId`,
      name: `Article ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `authorId`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `authorId`,
      name: `Author ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `status`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `status`,
      name: `Status`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false,
      export: true
    }
  ],
  [
    `affiliationYear`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `affiliationYear`,
      name: `Affiliation year`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true
    }
  ],
  [
    `projects`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `projects`,
      name: `Projects`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `projectsData`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `projectsData`,
      name: `Projects data`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: true,
      isEditable: false
    }
  ],
  [
    `number_of_publications`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `number_of_publications`,
      name: `Number of publications`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      tierField: false,
      isReportFilterField: true
    }
  ],
  [
    `number_of_clinical_trials`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `number_of_clinical_trials`,
      name: `Number of clinical trials`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      tierField: false,
      isReportFilterField: true
    }
  ],
  [
    `c_proj_impact_tier`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_impact_tier`,
      name: `Impact tier`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_impact_tier`
    }
  ],
  [
    `c_proj_bu1_potential1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_potential1`,
      name: `Potential tier (1)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_potential1`
    }
  ],
  [
    `c_proj_bu1_potential2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_potential2`,
      name: `Potential tier (2)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_potential1`
    }
  ],
  [
    `c_proj_patient_potential1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_patient_potential1`,
      name: `Patient potential 1 (#indication, KAM)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_patient_potential1`,
      type: `number`
    }
  ],
  [
    `c_proj_patient_potential2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_patient_potential2`,
      name: `Patient potential 2 (#indication, source)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_patient_potential2`,
      type: `number`
    }
  ],
  [
    `c_proj_patient_potential3`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_patient_potential3`,
      name: `Patient potential 3 (#indication, source)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_patient_potential3`,
      type: `number`
    }
  ],
  [
    `c_proj_patient_potential4`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_patient_potential4`,
      name: `Patient potential 4 (#indication, source)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_patient_potential4`,
      type: `number`
    }
  ],
  [
    `c_proj_patient_potential5`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_patient_potential5`,
      name: `Patient potential 5 (#indication, source)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_patient_potential5`,
      type: `number`
    }
  ],
  [
    `patient_utilisation1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `patient_utilisation1`,
      name: `Patient utilisation (#indication, KAM)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}patient_utilisation1`,
      type: `number`
    }
  ],
  [
    `sales_latest_period`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `sales_latest_period`,
      name: `Sales latest period`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}sales_latest_period`,
      type: `number`
    }
  ],
  [
    `sales_patient_equivalents`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `sales_patient_equivalents`,
      name: `Sales patient equivalents`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}sales_patient_equivalents`,
      type: `number`
    }
  ],
  [
    `sales_momentum_short`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `sales_momentum_short`,
      name: `Sales momentum short`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}sales_momentum_short`,
      type: `number`
    }
  ],
  [
    `sales_momentum_long`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `sales_momentum_long`,
      name: `Sales momentum long`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}sales_momentum_long`,
      type: `number`
    }
  ],
  [
    `c_proj_bu1_patient_group1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_patient_group1`,
      name: `Patient group treated (1)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_patient_group1`
    }
  ],
  [
    `c_proj_bu1_patient_group2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_patient_group2`,
      name: `Patient group treated (2)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_patient_group2`
    }
  ],
  [
    `c_proj_bu1_patient_group3`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_patient_group3`,
      name: `Patient group treated (3)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_patient_group3`
    }
  ],
  [
    `c_proj_bu1_use1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_use1`,
      name: `Product use tier (1)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_use1`
    }
  ],
  [
    `c_proj_bu1_use2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_use2`,
      name: `Product use tier (2)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_use2`
    }
  ],
  [
    `c_proj_bu1_use3`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_use3`,
      name: `Product use tier (3)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      tierField: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_use3`
    }
  ],
  [
    `c_proj_bu1_clin1`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_clin1`,
      name: `Clinical trials tier (1)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      tierField: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_clin1`
    }
  ],
  [
    `c_proj_bu1_clin2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_bu1_clin2`,
      name: `Clinical trials tier (2)`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: true,
      export: true,
      requireValidation: true,
      isProjectData: true,
      isReportFilterField: true,
      path: `projectsData${PATH_DELIMITER}c_proj_bu1_clin2`
    }
  ],
  [
    `c_gene_therapy_center`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_gene_therapy_center`,
      name: `Gene therapy center`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      isReportFilterField: true,
      type: `boolean`,
      export: true
    }
  ],
  [
    `c_proj_procedures`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_procedures`,
      name: `Surgical procedures`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isProjectData: true,
      path: `projectsData${PATH_DELIMITER}c_proj_procedures`
    }
  ],
  [
    `c_proj_treatment_center`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_treatment_center`,
      name: `Treatment center`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isProjectData: true,
      isReportFilterField: true,
      type: `boolean`,
      path: `projectsData${PATH_DELIMITER}c_proj_treatment_center`
    }
  ],
  [
    `c_clinical_trials`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_clinical_trials`,
      name: `Clinical trials`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      isReportFilterField: true,
      export: true
    }
  ],
  [
    `c_aliases`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_aliases`,
      name: `Aliases`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `valueScore`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `valueScore`,
      name: `Value score`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      path: `projectsData${PATH_DELIMITER}valueScore`,
      isFilterField: true,
      isReportFilterField: true,
      visibleInRaport: true
    }
  ],
  [
    `potentialScore`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `potentialScore`,
      name: `Potential score`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      path: `projectsData${PATH_DELIMITER}potentialScore`,
      isFilterField: true,
      isReportFilterField: true,
      visibleInRaport: true
    }
  ],
  [
    `utilScore`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `utilScore`,
      name: `Utilisation score`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      path: `projectsData${PATH_DELIMITER}utilScore`,
      isFilterField: true,
      isReportFilterField: true,
      visibleInRaport: true
    }
  ],
  [
    `clinicalTrialsScore`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `clinicalTrialsScore`,
      name: `Clinical trials score`,
      isSortable: true,
      visibleInSummary: true,
      systemProp: false,
      isEditable: false,
      path: `projectsData${PATH_DELIMITER}clinicalTrialsScore`,
      isFilterField: true,
      isReportFilterField: true,
      visibleInRaport: true
    }
  ],
  [
    `c_proj_clinical_trials`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_clinical_trials`,
      name: `Clinical trials`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      path: `projectsData${PATH_DELIMITER}c_proj_clinical_trials`
    }
  ],
  [
    `c_zip`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_zip`,
      name: `ZIP`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_US_AHA_ID`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_AHA_ID`,
      name: `US AHA ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_US_GPO_name`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_GPO_name`,
      name: `US GPO name`,
      isSortable: true,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      usOnly: true,
      isReportFilterField: true,
      type: `string`,
      availableInTransformation: true
    }
  ],
  [
    `c_US_GPO_city`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_GPO_city`,
      name: `US GPO city`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      availableInTransformation: true
    }
  ],
  [
    `c_US_GPO_state`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_GPO_state`,
      name: `US GPO state`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `string`,
      availableInTransformation: true
    }
  ],
  [
    `c_US_directpurchase`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_directpurchase`,
      name: `Direct purchasing`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      isReportFilterField: true,
      type: `boolean`,
      availableInTransformation: true
    }
  ],
  [
    `c_US_systemID`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_systemID`,
      name: `US system ID`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_US_system_name`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_system_name`,
      name: `US system name`,
      isSortable: true,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      usOnly: true,
      availableInTransformation: true,
      type: `string`,
      isReportFilterField: true
    }
  ],
  [
    `c_US_system_city`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_system_city`,
      name: `US system city`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_US_system_state`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_system_state`,
      name: `US system State`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_country_systemID2`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_country_systemID2`,
      name: `Country system ID 2`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_country_systemID3`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_country_systemID3`,
      name: `Country system ID 3`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_inpatient_days`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_inpatient_days`,
      name: `Inpatient days`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_inpatient_surgeries`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_inpatient_surgeries`,
      name: `Inpatient surgeries`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_adultcardiacsurgery`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_adultcardiacsurgery`,
      name: `Adult cardiac surgery`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_trauma_center`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_trauma_center`,
      name: `Trauma level certification`,
      isSortable: false,
      visibleInSummary: false,
      isReportFilterField: true,
      systemProp: false,
      isEditable: false,
      export: true,
      tierField: true
    }
  ],
  [
    `c_US_medicaremedicaid`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_US_medicaremedicaid`,
      name: `Medicare/Medicaid proportion`,
      isSortable: false,
      visibleInSummary: false,
      isReportFilterField: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_proj_CABP_surgeries`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_CABP_surgeries`,
      name: `Cardio pulmonary bypass surgeries/year`,
      isSortable: false,
      visibleInSummary: false,
      isReportFilterField: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_proj_periop_hemorrhage`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_proj_periop_hemorrhage`,
      name: `perioperative hemorrhages/year`,
      isSortable: false,
      visibleInSummary: false,
      isReportFilterField: true,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `c_decile`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `c_decile`,
      name: `Decile for the project`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}c_decile`,
      isProjectData: true,
      isReportFilterField: true,
      type: `number`
    }
  ],
  [
    `custom_metrics`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `custom_metrics`,
      name: `Custom Metrics`,
      isTagField: true,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true
    }
  ],
  [
    `center_vision`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center_vision`,
      name: `Center vision`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}center_vision`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `center_growth_driver`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center_growth_driver`,
      name: `Center growth driver`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}center_growth_driver`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `center_growth_obstacle`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center_growth_obstacle`,
      name: `Center growth obstacle`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}center_growth_obstacle`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `center_objectives`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center_objectives`,
      name: `Center objectives`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}center_objectives`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `treatment_goals_enabled`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treatment_goals_enabled`,
      name: `Center goals enabled`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}treatment_goals_enabled`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `treatment_option_total_count`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treatment_option_total_count`,
      name: `Center therapy total count`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}treatment_option_total_count`,
      isProjectData: true,
      isReportFilterField: true,
      type: `number`
    }
  ],
  [
    `treatment_options_counts`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treatment_options_counts`,
      name: `Center therapy counts`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}treatment_options_counts`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `treatment_options_validated`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treatment_options_validated`,
      name: `Center therapy validated`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}treatment_options_validated`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `treatment_uses_enabled`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `treatment_uses_enabled`,
      name: `Center uses enabled`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}treatment_uses_enabled`,
      isProjectData: true,
      isReportFilterField: true
    }
  ],
  [
    `center_decision_makers`,
    {
      columnWidth: SharedConstants.DEFAULT_COLUMN_WIDTH,
      fieldId: `center_decision_makers`,
      name: `Center decision makers`,
      isSortable: false,
      visibleInSummary: false,
      systemProp: false,
      isEditable: false,
      export: true,
      path: `projectsData${PATH_DELIMITER}center_decision_makers`,
      isProjectData: true,
      isReportFilterField: true,
      type: `string`
    }
  ]
]);
