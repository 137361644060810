import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { FieldType } from '@shared/Types/FieldType';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { PRODUCT_USE_MAPPINGS } from '@shared/db-fields-mappings/PRODUCT_USE_MAPPINGS';
import { TCategory } from '@shared/interfaces/TCategory';
import { TFieldNameMappingItem } from '@shared/interfaces/TFieldNameMappingItem';
import {
  TProject,
  TTagItem,
  TTierFieldItem,
} from '@shared/interfaces/TProject';
import { TRemoveCategory } from '@shared/interfaces/TRemoveCategory';
import { TRemoveProject } from '@shared/interfaces/TRemoveProject';
import { TUpdateProject } from '@shared/interfaces/TUpdateProject';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService, TLoadingItem } from './LoadingService';
import { SSEService } from './SSEService';
import { AuthService, TCurrentUser } from './auth/auth.service';
import { PagingService } from './paging/PagingService';

export interface TUpdateScoresParams {
  categoryId: string;
  projectId: string;
  callback?: () => void;
  recalculateTotals?: boolean;
}

@Injectable()
export class CategoriesService {
  private _categories: BehaviorSubject<TCategory[]> = new BehaviorSubject<
    TCategory[]
  >([]);

  private _selectedProject: BehaviorSubject<TProject | undefined> =
    new BehaviorSubject<TProject | undefined>(undefined);

  constructor(
    private _loadingService: LoadingService,
    private _http: HttpClient,
    private _pagingService: PagingService,
    private _authService: AuthService,
    private _SSEService: SSEService
  ) {}

  public getFieldDescription(field: string, projectId: string): string {
    const project: TProject | undefined = this.getProject(projectId);
    if (!isNullOrUndefined(project)) {
      const fieldFromSettings: TFieldNameMappingItem | undefined = [
        ...project.centerFields,
        ...project.hcpFields,
      ].find((f) => f.fieldName === field);
      if (fieldFromSettings !== undefined) {
        return fieldFromSettings.filedViewValue;
      }
    }
    const allMappings = new Map([
      ...CENTER_DB_MAPPINGS,
      ...HCP_DB_MAPPINGS,
      ...PRODUCT_USE_MAPPINGS,
    ]);
    return allMappings.get(field)?.name ?? `N/A`;
  }

  public getTiersForField(
    projectId: string,
    fieldId: string,
    tierType: FieldType
  ): TTierFieldItem[] {
    const project: TProject | undefined = this.getProject(projectId);
    if (!isNullOrUndefined(project)) {
      switch (tierType) {
        case 'center':
          return (
            (project.centerTierFieldDefinitions ?? []).find(
              (t) => t.fieldId === fieldId
            )?.tiers ?? []
          );
        case 'hcp':
          return (
            (project.hcpTierFieldDefinitions ?? []).find(
              (t) => t.fieldId === fieldId
            )?.tiers ?? []
          );
      }
    }
    return [];
  }

  public getTagsForField(
    projectId: string,
    fieldId: string,
    tierType: FieldType
  ): TTagItem[] {
    const project: TProject | undefined = this.getProject(projectId);
    if (!isNullOrUndefined(project)) {
      switch (tierType) {
        case 'center':
          return (
            (project.centerTagsDefinitions ?? []).find(
              (t) => t.connEctedFieldId === fieldId
            )?.tags ?? []
          );
        case 'hcp':
          return (
            (project.hcpTagsDefinitions ?? []).find(
              (t) => t.connEctedFieldId === fieldId
            )?.tags ?? []
          );
      }
    }
    return [];
  }

  public getProjectTags(projectId: string): TTagItem[] {
    const project: TProject | undefined = this.getProject(projectId);
    if (!isNullOrUndefined(project)) {
      const hcpTags = project?.hcpTagsDefinitions.map((t) => t.tags).flat();
      // const centerTags = project?.centerTagsDefinitions
      //   .map((t) => t.tags)
      //   .flat();
      // return [...hcpTags, ...centerTags];
      return hcpTags;
    }
    return [];
  }

  public getSelectedProjectObservable(): Observable<TProject | undefined> {
    return this._selectedProject.asObservable();
  }

  public getSelectedProject(): TProject | undefined {
    return this._selectedProject.value;
  }

  public setSelectedProject(projectId: string): void {
    const project: TProject | undefined = this.getProject(projectId);
    this._selectedProject.next(project);
  }

  public getCategoryFromProjectId(projectId: string): TCategory | undefined {
    return this.getCategories().find((c) =>
      c.projects.map((p) => p.id).includes(projectId)
    );
  }

  public getCategoriesObservable(): Observable<TCategory[]> {
    return this._categories.asObservable();
  }

  public getCategories(): TCategory[] {
    return this._categories.value;
  }

  public getCategory(categoryId: string): TCategory {
    const category: TCategory | undefined = this.getCategories().find(
      (c) => categoryId === c.categoryId
    );
    if (category === undefined) {
      throw new Error(`Invalid category id`);
    }
    return category;
  }

  public getProject(projectId: string): TProject | undefined {
    const projects: TProject[] = this.getCategories()
      .map((c) => {
        return c.projects;
      })
      .flat();
    const project: TProject | undefined = projects.find(
      (p) => p.id === projectId
    );
    return project;
  }

  public setCategories(callback?: () => void): void {
    const currentUser: TCurrentUser = this._authService.getCurrentUser();
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching categories`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCategories',
      params: ``,
    });
    this._http.get<TCategory[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        if (currentUser?.level === UserLevel.Admin) {
          this._categories.next(results);
        } else {
          const filteredCategories: TCategory[] = results.filter((c) =>
            currentUser?.categories?.includes(c.categoryId)
          );
          filteredCategories.forEach((c) => {
            c.projects = c.projects.filter((p) =>
              currentUser?.projects?.includes(p.id)
            );
          });
          this._categories.next(filteredCategories);
        }

        if (callback !== undefined) {
          callback();
        }
        const projects: TProject[] = results.map((c) => c.projects).flat();
        this._pagingService.addIfNotExists(projects);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public updateHCPsScores(params: TUpdateScoresParams): void {
    const { categoryId, projectId, callback, recalculateTotals } = params;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating HCPs scores`);
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      () => {
        this.setCategories();
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        if (callback !== undefined) {
          callback();
        }
      },
      () => {
        const url: string = Endpoints.BUILD_URL('score', {
          base: environment.apiUrl,
          endpoint: 'updateHCPsWithScore',
          params: `?categoryId=${categoryId}&projectId=${projectId}&recalculateTotals=${
            recalculateTotals ?? false
          }`,
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: () => {},
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public updateCenterScores(params: TUpdateScoresParams): void {
    const { categoryId, projectId, callback, recalculateTotals } = params;
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Updating centers scores`
    );
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService.handleProcessArticlesStatusEvent(
      () => {
        this.setCategories();
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        if (callback !== undefined) {
          callback();
        }
      },
      () => {
        const url: string = Endpoints.BUILD_URL('score', {
          base: environment.apiUrl,
          endpoint: 'updateCentersWithScore',
          params: `?categoryId=${categoryId}&projectId=${projectId}&recalculateTotals=${
            recalculateTotals ?? false
          }`,
        });
        this._http.get<void>(url).subscribe({
          error: (err) => {
            console.error(err);
            this._loadingService.onError();
          },
          next: () => {},
        });
      },
      () => {
        this._loadingService.removeItem(loadingItem);
        this._loadingService.onError();
      }
    );
  }

  public updateCategories(categories: TCategory[]): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating categories`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'updateCategories',
      params: ``,
    });
    const response = this._http.post<TCategory[]>(url, categories);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        console.log(res);
        // this._categories.next(res);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public updateProject(
    categoryId: string,
    newProject: TProject,
    callback?: () => void,
    shouldRecalculateProject: boolean = false
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating project`);
    this._loadingService.addLoadingItem(loadingItem);
    this._projectValidator(newProject);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'updateCategory',
      params: ``,
    });
    const payload: TUpdateProject = {
      categoryId: categoryId,
      project: newProject,
      shouldRecalculateProject,
    };
    if (shouldRecalculateProject) {
      this._SSEService.handleProcessArticlesStatusEvent(() => {
        this.setCategories();
      });
    }
    const response = this._http.post<TCategory[]>(url, payload);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const projects: TProject[] = res.map((c) => c.projects).flat();
        this._pagingService.addIfNotExists(projects);
        if (callback !== undefined) {
          callback();
        }
        this._categories.next(res);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public removeProject(
    categoryId: string,
    projectId: string,
    callback?: () => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Removing project`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'removeProject',
      params: ``,
    });
    const payload: TRemoveProject = {
      categoryId: categoryId,
      projectId: projectId,
    };
    const response = this._http.post<TCategory[]>(url, payload);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        this._categories.next(res);
        if (callback !== undefined) {
          callback();
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public removeCategory(categoryId: string, callback?: () => void): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Removing category`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'removeCategory',
      params: ``,
    });
    const payload: TRemoveCategory = {
      categoryId: categoryId,
    };
    const response = this._http.post<TCategory[]>(url, payload);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        this._categories.next(res);
        if (callback !== undefined) {
          callback();
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public createCategory(category: TCategory, callback?: () => void) {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Creating category`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'createCategory',
      params: ``,
    });
    const response = this._http.post<TCategory[]>(url, category);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        this._categories.next(res);
        if (callback !== undefined) {
          callback();
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  private _projectValidator(project: TProject): void {
    if (project.tiersDefinitions === undefined) {
      project.tiersDefinitions = {
        center: [],
        centerPotential: [],
        centerUtil: [],
        clinicalTrials: [],
        hcpEngagement: [],
        hcpInfluence: [],
        hcpValue: [],
        risingStar: [],
        participants: [],
        hcpProductUse: [],
      };
    }
  }
}
