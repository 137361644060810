import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { TTreatment } from '@shared/interfaces/TProject';

@Component({
  selector: 'app-treatment-item',
  templateUrl: './treatment-item.component.html',
  styleUrls: ['./treatment-item.component.scss'],
})
export class TreatmentItemComponent {
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor() {}

  @Input()
  public TData: TTreatment = {
    description: ``,
    name: ``,
  };

  @Input()
  public handleDelete: ((region: TTreatment) => void) | undefined;

  public deleteVisible(): boolean {
    return this.handleDelete !== undefined;
  }

  public deleteItem(): void {
    if (this.handleDelete !== undefined) {
      this.handleDelete(this.TData);
    }
  }
}
