<div class="settings-modal-content">
  <h2 class="my-4 mat-h2">Treatment Options</h2>
  <div>
    <app-treatment-item [TData]="newTreatmentOptionData"></app-treatment-item>
    <button
      mat-flat-button
      color="primary"
      (click)="add()"
      [disabled]="!addAvailable()"
    >
      <span>Add</span>
    </button>
  </div>
  <div *ngIf="getTreatmentOptions().length > 0">
    <h3 class="my-4">Existing options</h3>
    <div *ngFor="let option of getTreatmentOptions()">
      <app-treatment-item [TData]="option" [handleDelete]="remove.bind(this)">
      </app-treatment-item>
    </div>
  </div>
</div>
