import { Component, Input, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PRODUCT_USE_MAPPINGS } from '@shared/db-fields-mappings/PRODUCT_USE_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import {
  TCenter,
  TCenterProjectsData,
  buildCenterMock,
} from '@shared/interfaces/TCenter';
import {
  TProductSalesTableData,
  convertToTableData,
} from '@shared/interfaces/TProductUse';
import { TProject, TRegion } from '@shared/interfaces/TProject';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import {
  ICommonTableParams,
  PaginationVisibility,
  SortType,
  TCenterSalesTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from 'src/app/common-table/common-table.component.types';
import {
  TCenterParams,
  TComponentType,
} from 'src/app/interfaces/TComponentParams';
import { CentersService } from 'src/app/services/CentersService';
import { PDFService } from 'src/app/services/PDFService';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';

@Component({
  selector: 'app-sales-table',
  templateUrl: './sales-table.component.html',
  styleUrls: ['./sales-table.component.scss'],
})
export class SalesTableComponent {
  @Input() public centerParams: TCenterParams = {
    __type: TComponentType.center,
    categoryId: ``,
    centerId: ``,
    projectId: ``,
  };
  @Input() public centerData: TCenter = {
    ...buildCenterMock({}),
  };

  public columns: TDataColumnDef<TCenterSalesTableRow>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(PRODUCT_USE_MAPPINGS.values()).filter(
        (f) => f.visibleInShortSummary
      ),
    }),
  ];

  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 25, 50, 100, 1000];
  public pageIndex: number = 0;

  // @deprecated
  public pdfSrc: Uint8Array = new Uint8Array([]);
  public salesData1Src: Uint8Array = new Uint8Array([]);
  public salesData2Src: Uint8Array = new Uint8Array([]);
  public region1Data: TRegion = { description: ``, name: ``, salesData: `` };
  public region2Data: TRegion = { description: ``, name: ``, salesData: `` };

  public params: ICommonTableParams<TTableRowType, TTDataType>;
  public dataSource: MatTableDataSource<TCenterSalesTableRow>;
  public salesCount: number = 0;
  public productSales: TProductSalesTableData[] = [];

  constructor(
    private _pdfService: PDFService,
    private _centersService: CentersService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['centerData'] && this.isCenterSelected()) {
      const projectData = this.centerData.projectsData.find(
        (p) => p.projectId === this.centerParams.projectId
      );
      this._getProjectProductUseData(projectData);
      this.params = this.getTableParams();
    }
  }

  private getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: this.salesCount,
      dataLoaded: true,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(PRODUCT_USE_MAPPINGS.values())
        .filter((f) => f.visibleInShortSummary)
        .map((f) => f.fieldId),
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageSizeOptions: this.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      downloadCallback: undefined,
      paginationVisibility: PaginationVisibility.Simple,
      importCallback: void 0,
      area: SearchArea.None,
      projectId: this.centerParams.projectId,
      selection: false,
      actionsAvailable: false,
      sortType: SortType.Simple,
      simpleSortHeaders: [`Product Name`, `Time Period`, `Formulary Status`],
    };
  }

  public async paginationCallback(e: PageEvent): Promise<void> {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  private _getProjectProductUseData(
    projectData: TCenterProjectsData | undefined
  ): void {
    if (projectData === undefined || !projectData.products_use) {
      this.productSales = [];
      this.salesCount = 0;
      this.dataSource.data = [];
      return;
    }
    this.productSales = convertToTableData(projectData.products_use);
    this.salesCount = this.productSales.length;
    this.dataSource.data = this.productSales.map((sale, index) => {
      return {
        ...sale,
        position: index,
        __type: TableRowType.CenterSales,
      };
    });
  }

  // @deprecated
  public showSalesData1(): void {
    this.pdfSrc = this.salesData1Src;
  }

  // @deprecated
  public getPDFWrapperCSS(): string {
    return isNullOrEmpty(this.pdfSrc) ? `pdf-wrapper--hidden` : ``;
  }

  // @deprecated
  public showSalesData2(): void {
    this.pdfSrc = this.salesData2Src;
  }

  // @deprecated
  private _setSalesData(project: TProject): void {
    const projectData: TCenterProjectsData =
      this._centersService.getProjectData(
        this.centerData,
        this.centerParams.projectId
      );
    const accountRegion1 = project.regions1.find(
      (r) => projectData.c_project_region1 === r.name
    );
    const accountRegion2 = project.regions1.find(
      (r) => projectData.c_project_region2 === r.name
    );
    if (accountRegion1 !== undefined && accountRegion1.salesData.length > 0) {
      this.region1Data = accountRegion1;
      this._pdfService.loadSalesData(accountRegion1.salesData, (res) => {
        this.salesData1Src = res;
        this.showSalesData1();
      });
    }
    if (accountRegion2 !== undefined && accountRegion2.salesData.length > 0) {
      this.region2Data = accountRegion2;
      this._pdfService.loadSalesData(accountRegion2.salesData, (res) => {
        this.salesData2Src = res;
        if (accountRegion1?.salesData.length === 0) {
          this.showSalesData2();
        }
      });
    }
  }

  private isCenterSelected(): boolean {
    return this.centerData.c_id !== `` && this.centerParams.centerId !== ``;
  }
}
